import { accountTypes } from "./accountTypes";

export const getIsDealer = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile?.protectedData?.accountType === accountTypes.dealer;
}

export const getAccountType = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile?.protectedData?.accountType;
}

export const getFullName = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes?.profile?.firstName + " " + currentUser.attributes?.profile?.lastName
}

export const getPhoneNumber = (currentUser) => {
    if (!currentUser) return null;

    return currentUser.attributes.profile.protectedData.phoneNumber;
}