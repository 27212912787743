export const carMakers =
    [
        {
            "option": "ac",
            "label": "AC"
        },
        {
            "option": "am-general",
            "label": "AM General"
        },
        {
            "option": "amc",
            "label": "AMC"
        },
        {
            "option": "atom",
            "label": "ATOM"
        },
        {
            "option": "abarth",
            "label": "Abarth"
        },
        {
            "option": "acura",
            "label": "Acura"
        },
        {
            "option": "adler",
            "label": "Adler"
        },
        {
            "option": "aion",
            "label": "Aion"
        },
        {
            "option": "aito",
            "label": "Aito"
        },
        {
            "option": "aiways",
            "label": "Aiways"
        },
        {
            "option": "aixam",
            "label": "Aixam"
        },
        {
            "option": "alfa-romeo",
            "label": "Alfa Romeo"
        },
        {
            "option": "alpina",
            "label": "Alpina"
        },
        {
            "option": "alpine",
            "label": "Alpine"
        },
        {
            "option": "amberauto",
            "label": "Amberauto"
        },
        {
            "option": "ambertruck",
            "label": "Ambertruck"
        },
        {
            "option": "apal",
            "label": "Apal"
        },
        {
            "option": "arcfox",
            "label": "Arcfox"
        },
        {
            "option": "ariel",
            "label": "Ariel"
        },
        {
            "option": "aro",
            "label": "Aro"
        },
        {
            "option": "asia",
            "label": "Asia"
        },
        {
            "option": "aston-martin",
            "label": "Aston Martin"
        },
        {
            "option": "auburn",
            "label": "Auburn"
        },
        {
            "option": "audi",
            "label": "Audi"
        },
        {
            "option": "aurus",
            "label": "Aurus"
        },
        {
            "option": "austin",
            "label": "Austin"
        },
        {
            "option": "austin-healey",
            "label": "Austin Healey"
        },
        {
            "option": "auto-union",
            "label": "Auto Union"
        },
        {
            "option": "autobianchi",
            "label": "Autobianchi"
        },
        {
            "option": "autocam",
            "label": "Autocam"
        },
        {
            "option": "avatr",
            "label": "Avatr"
        },
        {
            "option": "baw",
            "label": "BAW"
        },
        {
            "option": "bmw",
            "label": "BMW"
        },
        {
            "option": "byd",
            "label": "BYD"
        },
        {
            "option": "baic",
            "label": "Baic"
        },
        {
            "option": "bajaj",
            "label": "Bajaj"
        },
        {
            "option": "baltijas-dzips",
            "label": "Baltijas Dzips"
        },
        {
            "option": "baojun",
            "label": "Baojun"
        },
        {
            "option": "batmobile",
            "label": "Batmobile"
        },
        {
            "option": "beijing",
            "label": "Beijing"
        },
        {
            "option": "belgee",
            "label": "Belgee"
        },
        {
            "option": "bentley",
            "label": "Bentley"
        },
        {
            "option": "bertone",
            "label": "Bertone"
        },
        {
            "option": "bilenkin",
            "label": "Bilenkin"
        },
        {
            "option": "bio-auto",
            "label": "Bio auto"
        },
        {
            "option": "bitter",
            "label": "Bitter"
        },
        {
            "option": "blaval",
            "label": "Blaval"
        },
        {
            "option": "borgward",
            "label": "Borgward"
        },
        {
            "option": "brabus",
            "label": "Brabus"
        },
        {
            "option": "brilliance",
            "label": "Brilliance"
        },
        {
            "option": "bristol",
            "label": "Bristol"
        },
        {
            "option": "bufori",
            "label": "Bufori"
        },
        {
            "option": "bugatti",
            "label": "Bugatti"
        },
        {
            "option": "buick",
            "label": "Buick"
        },
        {
            "option": "byvin",
            "label": "Byvin"
        },
        {
            "option": "cadillac",
            "label": "Cadillac"
        },
        {
            "option": "callaway",
            "label": "Callaway"
        },
        {
            "option": "carbodies",
            "label": "Carbodies"
        },
        {
            "option": "caterham",
            "label": "Caterham"
        },
        {
            "option": "chana",
            "label": "Chana"
        },
        {
            "option": "changfeng",
            "label": "ChangFeng"
        },
        {
            "option": "changan",
            "label": "Changan"
        },
        {
            "option": "changan-kuayue (kyc)",
            "label": "Changan Kuayue (KYC)"
        },
        {
            "option": "changhe",
            "label": "Changhe"
        },
        {
            "option": "chery",
            "label": "Chery"
        },
        {
            "option": "cheryexeed",
            "label": "CheryExeed"
        },
        {
            "option": "chevrolet",
            "label": "Chevrolet"
        },
        {
            "option": "chrysler",
            "label": "Chrysler"
        },
        {
            "option": "ciimo",
            "label": "Ciimo"
        },
        {
            "option": "citroen",
            "label": "Citroen"
        },
        {
            "option": "cizeta",
            "label": "Cizeta"
        },
        {
            "option": "coda",
            "label": "Coda"
        },
        {
            "option": "coggiola",
            "label": "Coggiola"
        },
        {
            "option": "combat",
            "label": "Combat"
        },
        {
            "option": "cord",
            "label": "Cord"
        },
        {
            "option": "cupra",
            "label": "Cupra"
        },
        {
            "option": "dfm",
            "label": "DFM"
        },
        {
            "option": "dfsk",
            "label": "DFSK"
        },
        {
            "option": "dkw",
            "label": "DKW"
        },
        {
            "option": "dr",
            "label": "DR"
        },
        {
            "option": "ds",
            "label": "DS"
        },
        {
            "option": "dw-hower",
            "label": "DW Hower"
        },
        {
            "option": "dacia",
            "label": "Dacia"
        },
        {
            "option": "dadi",
            "label": "Dadi"
        },
        {
            "option": "daewoo",
            "label": "Daewoo"
        },
        {
            "option": "daihatsu",
            "label": "Daihatsu"
        },
        {
            "option": "daimler",
            "label": "Daimler"
        },
        {
            "option": "dallara",
            "label": "Dallara"
        },
        {
            "option": "dallas",
            "label": "Dallas"
        },
        {
            "option": "datsun",
            "label": "Datsun"
        },
        {
            "option": "dayun",
            "label": "Dayun"
        },
        {
            "option": "de-tomaso",
            "label": "De Tomaso"
        },
        {
            "option": "delorean",
            "label": "DeLorean"
        },
        {
            "option": "desoto",
            "label": "DeSoto"
        },
        {
            "option": "deco-rides",
            "label": "Deco Rides"
        },
        {
            "option": "delage",
            "label": "Delage"
        },
        {
            "option": "denza",
            "label": "Denza"
        },
        {
            "option": "derways",
            "label": "Derways"
        },
        {
            "option": "dodge",
            "label": "Dodge"
        },
        {
            "option": "dongfeng",
            "label": "DongFeng"
        },
        {
            "option": "dongfeng-forthing",
            "label": "Dongfeng Forthing"
        },
        {
            "option": "doninvest",
            "label": "Doninvest"
        },
        {
            "option": "donkervoort",
            "label": "Donkervoort"
        },
        {
            "option": "e-car",
            "label": "E-Car"
        },
        {
            "option": "exeed",
            "label": "EXEED"
        },
        {
            "option": "eagle",
            "label": "Eagle"
        },
        {
            "option": "eagle-cars",
            "label": "Eagle Cars"
        },
        {
            "option": "efini",
            "label": "Efini"
        },
        {
            "option": "enoreve",
            "label": "Enoreve"
        },
        {
            "option": "enovate",
            "label": "Enovate"
        },
        {
            "option": "everus",
            "label": "Everus"
        },
        {
            "option": "evolute",
            "label": "Evolute"
        },
        {
            "option": "excalibur",
            "label": "Excalibur"
        },
        {
            "option": "faw",
            "label": "FAW"
        },
        {
            "option": "fso",
            "label": "FSO"
        },
        {
            "option": "facel-vega",
            "label": "Facel Vega"
        },
        {
            "option": "fengon",
            "label": "Fengon"
        },
        {
            "option": "ferrari",
            "label": "Ferrari"
        },
        {
            "option": "fiat",
            "label": "Fiat"
        },
        {
            "option": "fisker",
            "label": "Fisker"
        },
        {
            "option": "flanker",
            "label": "Flanker"
        },
        {
            "option": "ford",
            "label": "Ford"
        },
        {
            "option": "foton",
            "label": "Foton"
        },
        {
            "option": "fuqi",
            "label": "Fuqi"
        },
        {
            "option": "gac",
            "label": "GAC"
        },
        {
            "option": "gaz",
            "label": "GAZ"
        },
        {
            "option": "gmc",
            "label": "GMC"
        },
        {
            "option": "gp",
            "label": "GP"
        },
        {
            "option": "geely",
            "label": "Geely"
        },
        {
            "option": "genesis",
            "label": "Genesis"
        },
        {
            "option": "geo",
            "label": "Geo"
        },
        {
            "option": "goggomobil",
            "label": "Goggomobil"
        },
        {
            "option": "gonow",
            "label": "Gonow"
        },
        {
            "option": "gordon",
            "label": "Gordon"
        },
        {
            "option": "great-wall",
            "label": "Great Wall"
        },
        {
            "option": "groz",
            "label": "Groz"
        },
        {
            "option": "hsv",
            "label": "HSV"
        },
        {
            "option": "hafei",
            "label": "Hafei"
        },
        {
            "option": "haima",
            "label": "Haima"
        },
        {
            "option": "hanomag",
            "label": "Hanomag"
        },
        {
            "option": "hanteng",
            "label": "Hanteng"
        },
        {
            "option": "haval",
            "label": "Haval"
        },
        {
            "option": "hawtai",
            "label": "Hawtai"
        },
        {
            "option": "heinkel",
            "label": "Heinkel"
        },
        {
            "option": "hennessey",
            "label": "Hennessey"
        },
        {
            "option": "hiphi",
            "label": "HiPhi"
        },
        {
            "option": "hindustan",
            "label": "Hindustan"
        },
        {
            "option": "hispano-suiza",
            "label": "Hispano-Suiza"
        },
        {
            "option": "holden",
            "label": "Holden"
        },
        {
            "option": "honda",
            "label": "Honda"
        },
        {
            "option": "hongqi",
            "label": "Hongqi"
        },
        {
            "option": "horch",
            "label": "Horch"
        },
        {
            "option": "hozon",
            "label": "Hozon"
        },
        {
            "option": "huaihai-(hoann)",
            "label": "Huaihai (Hoann)"
        },
        {
            "option": "huanghai",
            "label": "HuangHai"
        },
        {
            "option": "huazi",
            "label": "Huazi"
        },
        {
            "option": "hudson",
            "label": "Hudson"
        },
        {
            "option": "hummer",
            "label": "Hummer"
        },
        {
            "option": "hurtan",
            "label": "Hurtan"
        },
        {
            "option": "hycan",
            "label": "Hycan"
        },
        {
            "option": "hyundai",
            "label": "Hyundai"
        },
        {
            "option": "im-motors (zhiji)",
            "label": "IM Motors (Zhiji)"
        },
        {
            "option": "iveco",
            "label": "IVECO"
        },
        {
            "option": "izh",
            "label": "IZH"
        },
        {
            "option": "ineos",
            "label": "Ineos"
        },
        {
            "option": "infiniti",
            "label": "Infiniti"
        },
        {
            "option": "innocenti",
            "label": "Innocenti"
        },
        {
            "option": "international",
            "label": "International"
        },
        {
            "option": "invicta",
            "label": "Invicta"
        },
        {
            "option": "iran-khodro",
            "label": "Iran Khodro"
        },
        {
            "option": "isdera",
            "label": "Isdera"
        },
        {
            "option": "isuzu",
            "label": "Isuzu"
        },
        {
            "option": "jac",
            "label": "JAC"
        },
        {
            "option": "jmc",
            "label": "JMC"
        },
        {
            "option": "jmev",
            "label": "JMEV"
        },
        {
            "option": "jaecoo",
            "label": "Jaecoo"
        },
        {
            "option": "jaguar",
            "label": "Jaguar"
        },
        {
            "option": "jeep",
            "label": "Jeep"
        },
        {
            "option": "jensen",
            "label": "Jensen"
        },
        {
            "option": "jetour",
            "label": "Jetour"
        },
        {
            "option": "jetta",
            "label": "Jetta"
        },
        {
            "option": "jiangnan",
            "label": "Jiangnan"
        },
        {
            "option": "jinbei",
            "label": "Jinbei"
        },
        {
            "option": "jonway",
            "label": "Jonway"
        },
        {
            "option": "kg-mobility",
            "label": "KG Mobility"
        },
        {
            "option": "ktm-ag",
            "label": "KTM AG"
        },
        {
            "option": "kyc",
            "label": "KYC"
        },
        {
            "option": "kaiyi",
            "label": "Kaiyi"
        },
        {
            "option": "kanonir",
            "label": "Kanonir"
        },
        {
            "option": "karma",
            "label": "Karma"
        },
        {
            "option": "kawei",
            "label": "Kawei"
        },
        {
            "option": "kia",
            "label": "Kia"
        },
        {
            "option": "koenigsegg",
            "label": "Koenigsegg"
        },
        {
            "option": "lti",
            "label": "LTI"
        },
        {
            "option": "lamborghini",
            "label": "Lamborghini"
        },
        {
            "option": "lancia",
            "label": "Lancia"
        },
        {
            "option": "land-rover",
            "label": "Land Rover"
        },
        {
            "option": "landwind",
            "label": "Landwind"
        },
        {
            "option": "leapmotor",
            "label": "Leapmotor"
        },
        {
            "option": "lexus",
            "label": "Lexus"
        },
        {
            "option": "lixiang",
            "label": "LiXiang"
        },
        {
            "option": "liebao-motor",
            "label": "Liebao Motor"
        },
        {
            "option": "lifan",
            "label": "Lifan"
        },
        {
            "option": "ligier",
            "label": "Ligier"
        },
        {
            "option": "lincoln",
            "label": "Lincoln"
        },
        {
            "option": "livan",
            "label": "Livan"
        },
        {
            "option": "logem",
            "label": "Logem"
        },
        {
            "option": "lotus",
            "label": "Lotus"
        },
        {
            "option": "luaz",
            "label": "LuAZ"
        },
        {
            "option": "lucid",
            "label": "Lucid"
        },
        {
            "option": "luxeed",
            "label": "Luxeed"
        },
        {
            "option": "luxgen",
            "label": "Luxgen"
        },
        {
            "option": "lynk-& co",
            "label": "Lynk & Co"
        },
        {
            "option": "m-hero",
            "label": "M-Hero"
        },
        {
            "option": "mg",
            "label": "MG"
        },
        {
            "option": "mahindra",
            "label": "Mahindra"
        },
        {
            "option": "maple",
            "label": "Maple"
        },
        {
            "option": "marcos",
            "label": "Marcos"
        },
        {
            "option": "marlin",
            "label": "Marlin"
        },
        {
            "option": "marussia",
            "label": "Marussia"
        },
        {
            "option": "maruti",
            "label": "Maruti"
        },
        {
            "option": "maserati",
            "label": "Maserati"
        },
        {
            "option": "matra",
            "label": "Matra"
        },
        {
            "option": "maxeed",
            "label": "Maxeed"
        },
        {
            "option": "maxus",
            "label": "Maxus"
        },
        {
            "option": "maybach",
            "label": "Maybach"
        },
        {
            "option": "mazda",
            "label": "Mazda"
        },
        {
            "option": "mclaren",
            "label": "McLaren"
        },
        {
            "option": "mega",
            "label": "Mega"
        },
        {
            "option": "mercedes-benz",
            "label": "Mercedes-Benz"
        },
        {
            "option": "mercury",
            "label": "Mercury"
        },
        {
            "option": "messerschmitt",
            "label": "Messerschmitt"
        },
        {
            "option": "metrocab",
            "label": "Metrocab"
        },
        {
            "option": "microcar",
            "label": "Microcar"
        },
        {
            "option": "minelli",
            "label": "Minelli"
        },
        {
            "option": "mini",
            "label": "Mini"
        },
        {
            "option": "mitsubishi",
            "label": "Mitsubishi"
        },
        {
            "option": "mitsuoka",
            "label": "Mitsuoka"
        },
        {
            "option": "mobilize",
            "label": "Mobilize"
        },
        {
            "option": "monte-carlo",
            "label": "Monte Carlo"
        },
        {
            "option": "morgan",
            "label": "Morgan"
        },
        {
            "option": "morris",
            "label": "Morris"
        },
        {
            "option": "moskvich",
            "label": "Moskvich"
        },
        {
            "option": "nash",
            "label": "Nash"
        },
        {
            "option": "nio",
            "label": "Nio"
        },
        {
            "option": "nissan",
            "label": "Nissan"
        },
        {
            "option": "noble",
            "label": "Noble"
        },
        {
            "option": "nysa",
            "label": "Nysa"
        },
        {
            "option": "ora",
            "label": "ORA"
        },
        {
            "option": "oldsmobile",
            "label": "Oldsmobile"
        },
        {
            "option": "omoda",
            "label": "Omoda"
        },
        {
            "option": "opel",
            "label": "Opel"
        },
        {
            "option": "osca",
            "label": "Osca"
        },
        {
            "option": "oshan",
            "label": "Oshan"
        },
        {
            "option": "other-car",
            "label": "Other car"
        },
        {
            "option": "oting",
            "label": "Oting"
        },
        {
            "option": "overland",
            "label": "Overland"
        },
        {
            "option": "pgo",
            "label": "PGO"
        },
        {
            "option": "puch",
            "label": "PUCH"
        },
        {
            "option": "packard",
            "label": "Packard"
        },
        {
            "option": "pagani",
            "label": "Pagani"
        },
        {
            "option": "panoz",
            "label": "Panoz"
        },
        {
            "option": "paykan",
            "label": "Paykan"
        },
        {
            "option": "perodua",
            "label": "Perodua"
        },
        {
            "option": "peugeot",
            "label": "Peugeot"
        },
        {
            "option": "piaggio",
            "label": "Piaggio"
        },
        {
            "option": "pierce-arrow",
            "label": "Pierce-Arrow"
        },
        {
            "option": "plymouth",
            "label": "Plymouth"
        },
        {
            "option": "polar-stone (jishi)",
            "label": "Polar Stone (Jishi)"
        },
        {
            "option": "polestar",
            "label": "Polestar"
        },
        {
            "option": "pontiac",
            "label": "Pontiac"
        },
        {
            "option": "porsche",
            "label": "Porsche"
        },
        {
            "option": "premier",
            "label": "Premier"
        },
        {
            "option": "proton",
            "label": "Proton"
        },
        {
            "option": "puma",
            "label": "Puma"
        },
        {
            "option": "qiantu",
            "label": "Qiantu"
        },
        {
            "option": "qingling",
            "label": "Qingling"
        },
        {
            "option": "qiyuan",
            "label": "Qiyuan"
        },
        {
            "option": "qoros",
            "label": "Qoros"
        },
        {
            "option": "qvale",
            "label": "Qvale"
        },
        {
            "option": "ram",
            "label": "RAM"
        },
        {
            "option": "racing-car",
            "label": "Racing car"
        },
        {
            "option": "radar",
            "label": "Radar"
        },
        {
            "option": "rambler",
            "label": "Rambler"
        },
        {
            "option": "ravon",
            "label": "Ravon"
        },
        {
            "option": "reliant",
            "label": "Reliant"
        },
        {
            "option": "renaissance",
            "label": "Renaissance"
        },
        {
            "option": "renaissance-cars",
            "label": "Renaissance Cars"
        },
        {
            "option": "renault",
            "label": "Renault"
        },
        {
            "option": "renault-samsung",
            "label": "Renault Samsung"
        },
        {
            "option": "rezvani",
            "label": "Rezvani"
        },
        {
            "option": "rimac",
            "label": "Rimac"
        },
        {
            "option": "rinspeed",
            "label": "Rinspeed"
        },
        {
            "option": "rising-auto",
            "label": "Rising Auto"
        },
        {
            "option": "rivian",
            "label": "Rivian"
        },
        {
            "option": "roewe",
            "label": "Roewe"
        },
        {
            "option": "rolls-royce",
            "label": "Rolls-Royce"
        },
        {
            "option": "ronart",
            "label": "Ronart"
        },
        {
            "option": "rossa",
            "label": "Rossa"
        },
        {
            "option": "rover",
            "label": "Rover"
        },
        {
            "option": "rox",
            "label": "Rox"
        },
        {
            "option": "russo-balt",
            "label": "Russo-Balt"
        },
        {
            "option": "seat",
            "label": "SEAT"
        },
        {
            "option": "sma",
            "label": "SMA"
        },
        {
            "option": "smz",
            "label": "SMZ"
        },
        {
            "option": "swm",
            "label": "SWM"
        },
        {
            "option": "saab",
            "label": "Saab"
        },
        {
            "option": "saipa",
            "label": "Saipa"
        },
        {
            "option": "saleen",
            "label": "Saleen"
        },
        {
            "option": "samsung",
            "label": "Samsung"
        },
        {
            "option": "santana",
            "label": "Santana"
        },
        {
            "option": "saturn",
            "label": "Saturn"
        },
        {
            "option": "scion",
            "label": "Scion"
        },
        {
            "option": "sears",
            "label": "Sears"
        },
        {
            "option": "seres",
            "label": "Seres"
        },
        {
            "option": "seres-aito",
            "label": "Seres Aito"
        },
        {
            "option": "shanghai-maple",
            "label": "Shanghai Maple"
        },
        {
            "option": "shifeng",
            "label": "Shifeng"
        },
        {
            "option": "shuanghuan",
            "label": "ShuangHuan"
        },
        {
            "option": "simca",
            "label": "Simca"
        },
        {
            "option": "skoda",
            "label": "Skoda"
        },
        {
            "option": "skywell",
            "label": "Skywell"
        },
        {
            "option": "skyworth",
            "label": "Skyworth"
        },
        {
            "option": "smart",
            "label": "Smart"
        },
        {
            "option": "solaris",
            "label": "Solaris"
        },
        {
            "option": "sollers",
            "label": "Sollers"
        },
        {
            "option": "soueast",
            "label": "Soueast"
        },
        {
            "option": "spectre",
            "label": "Spectre"
        },
        {
            "option": "spyker",
            "label": "Spyker"
        },
        {
            "option": "ssangyong",
            "label": "SsangYong"
        },
        {
            "option": "steyr",
            "label": "Steyr"
        },
        {
            "option": "studebaker",
            "label": "Studebaker"
        },
        {
            "option": "subaru",
            "label": "Subaru"
        },
        {
            "option": "suzuki",
            "label": "Suzuki"
        },
        {
            "option": "tvr",
            "label": "TVR"
        },
        {
            "option": "tagaz",
            "label": "TagAZ"
        },
        {
            "option": "talbot",
            "label": "Talbot"
        },
        {
            "option": "tank",
            "label": "Tank"
        },
        {
            "option": "tata",
            "label": "Tata"
        },
        {
            "option": "tatra",
            "label": "Tatra"
        },
        {
            "option": "tazzari",
            "label": "Tazzari"
        },
        {
            "option": "tesla",
            "label": "Tesla"
        },
        {
            "option": "thairung",
            "label": "Thairung"
        },
        {
            "option": "think",
            "label": "Think"
        },
        {
            "option": "tianma",
            "label": "Tianma"
        },
        {
            "option": "tianye",
            "label": "Tianye"
        },
        {
            "option": "tofas",
            "label": "Tofas"
        },
        {
            "option": "toyota",
            "label": "Toyota"
        },
        {
            "option": "trabant",
            "label": "Trabant"
        },
        {
            "option": "tramontana",
            "label": "Tramontana"
        },
        {
            "option": "triumph",
            "label": "Triumph"
        },
        {
            "option": "trumpchi",
            "label": "Trumpchi"
        },
        {
            "option": "uaz",
            "label": "UAZ"
        },
        {
            "option": "ultima",
            "label": "Ultima"
        },
        {
            "option": "vaz-(lada)",
            "label": "VAZ (Lada)"
        },
        {
            "option": "vgv",
            "label": "VGV"
        },
        {
            "option": "vuhl",
            "label": "VUHL"
        },
        {
            "option": "vauxhall",
            "label": "Vauxhall"
        },
        {
            "option": "vector",
            "label": "Vector"
        },
        {
            "option": "venturi",
            "label": "Venturi"
        },
        {
            "option": "venucia",
            "label": "Venucia"
        },
        {
            "option": "vinfast",
            "label": "VinFast"
        },
        {
            "option": "volga",
            "label": "Volga"
        },
        {
            "option": "volkswagen",
            "label": "Volkswagen"
        },
        {
            "option": "volvo",
            "label": "Volvo"
        },
        {
            "option": "vortex",
            "label": "Vortex"
        },
        {
            "option": "voyah",
            "label": "Voyah"
        },
        {
            "option": "w-motors",
            "label": "W Motors"
        },
        {
            "option": "wey",
            "label": "WEY"
        },
        {
            "option": "wanderer",
            "label": "Wanderer"
        },
        {
            "option": "wartburg",
            "label": "Wartburg"
        },
        {
            "option": "weltmeister",
            "label": "Weltmeister"
        },
        {
            "option": "westfield",
            "label": "Westfield"
        },
        {
            "option": "wiesmann",
            "label": "Wiesmann"
        },
        {
            "option": "willys",
            "label": "Willys"
        },
        {
            "option": "wuling",
            "label": "Wuling"
        },
        {
            "option": "xev",
            "label": "XEV"
        },
        {
            "option": "xcite",
            "label": "Xcite"
        },
        {
            "option": "xiaopaoche-(ssc)",
            "label": "XiaoPaoChe (SSC)"
        },
        {
            "option": "xiaomi",
            "label": "Xiaomi"
        },
        {
            "option": "xin-kai",
            "label": "Xin Kai"
        },
        {
            "option": "xpeng",
            "label": "Xpeng"
        },
        {
            "option": "yema",
            "label": "Yema"
        },
        {
            "option": "yipai",
            "label": "Yipai"
        },
        {
            "option": "yo-mobil",
            "label": "Yo-mobil"
        },
        {
            "option": "yulon",
            "label": "Yulon"
        },
        {
            "option": "zaz",
            "label": "ZAZ"
        },
        {
            "option": "zil",
            "label": "ZIL"
        },
        {
            "option": "zx",
            "label": "ZX"
        },
        {
            "option": "zastava",
            "label": "Zastava"
        },
        {
            "option": "zeekr",
            "label": "Zeekr"
        },
        {
            "option": "zenos",
            "label": "Zenos"
        },
        {
            "option": "zenvo",
            "label": "Zenvo"
        },
        {
            "option": "zis",
            "label": "ZiS"
        },
        {
            "option": "zibar",
            "label": "Zibar"
        },
        {
            "option": "zotye",
            "label": "Zotye"
        },
        {
            "option": "icar",
            "label": "iCar"
        },
        {
            "option": "andere",
            "label": "Andere"
        }
    ]